import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import login from "../views/Login.vue";
import forgotPassword from "@/views/forgotPassword.vue";
import forgotPasswordSuccess from "@/views/forgotPasswordSuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  { path: "/forgotpassword", name: "forgot", component: forgotPassword },
  {
    path: "/forgotPasswordSuccess",
    name: "forgotSuccess",
    component: forgotPasswordSuccess,
  },
  {
    path: "/ExportVat",
    name: "ExportVat",
    component: () => import("@/views/ExportExcel/ExportVat.vue"),
  },
  {
    path: "/invc",
    name: "invc",
    component: () => import("@/views/ExportExcel/ExportInvoiceeee.vue"),
  },
  {
    path: "/Home",
    component: Homepage,
    children: [
      {
        path: "/ManageCategory",
        name: "ManageCategory",
        component: () => import("@/views/ManageCategory"),
      },
      {
        path: "/CreateCategory",
        name: "CreateCategory",
        component: () => import("@/components/Category/CreateCategory.vue"),
      },
      {
        path: "/EditCategory",
        name: "EditCategory",
        component: () => import("@/components/Category/EditCategory.vue"),
      },
      {
        path: "/ViewCategory",
        name: "ViewCategory",
        component: () => import("@/components/Category/ViewCategory.vue"),
      },
      {
        path: "/ManageType",
        name: "ManageType",
        component: () => import("@/views/ManageType"),
      },
      {
        path: "/CreateType",
        name: "CreateType",
        component: () => import("@/components/Type/CreateType.vue"),
      },
      {
        path: "/EditType",
        name: "EditType",
        component: () => import("@/components/Type/EditType.vue"),
      },
      {
        path: "/ViewType",
        name: "ViewType",
        component: () => import("@/components/Type/ViewType.vue"),
      },
      {
        path: "/ManageBrand",
        name: "ManageBrand",
        component: () => import("@/views/ManageBrand"),
      },
      {
        path: "/CreateBrand",
        name: "CreateBrand",
        component: () => import("@/components/Brand/CreateBrand.vue"),
      },
      {
        path: "/EditBrand",
        name: "EditBrand",
        component: () => import("@/components/Brand/EditBrand.vue"),
      },
      {
        path: "/ViewBrand",
        name: "ViewBrand",
        component: () => import("@/components/Brand/ViewBrand.vue"),
      },
      {
        path: "/CreateProduct",
        name: "CreateProduct",
        component: () => import("@/components/Product/CreateProduct.vue"),
      },
      {
        path: "/ManageProduct",
        name: "ManageProduct",
        component: () => import("@/views/ManageProduct"),
      },
      {
        path: "/ManageProduct365",
        name: "ManageProduct365",
        component: () => import("@/views/ManageProduct365"),
      },
      {
        path: "/CreateProduct",
        name: "CreateProduct",
        component: () => import("@/components/Product/CreateProduct.vue"),
      },
      {
        path: "/viewProduct",
        name: "viewProduct",
        component: () => import("@/components/Product/viewProduct.vue"),
      },
      {
        path: "/EditProduct",
        name: "EditProduct",
        component: () => import("@/components/Product/EditProduct.vue"),
      },
      {
        path: "/ManageUser",
        name: "ManageUser",
        component: () => import("@/views/ManageUser"),
      },
      {
        path: "/viewUser",
        name: "viewUser",
        component: () => import("@/components/User/viewUser.vue"),
      },
      {
        path: "/ManageOrder",
        name: "ManageOrder",
        component: () => import("@/views/ManageOrder"),
      },
      {
        path: "/viewOrder",
        name: "viewOrder",
        component: () => import("@/components/Order/viewOrder.vue"),
      },
      {
        path: "/EditOrder",
        name: "EditOrder",
        component: () => import("@/components/Order/EditOrder.vue"),
      },
      {
        path: "/ManageCompany",
        name: "ManageCompany",
        component: () => import("@/views/ManageCompany"),
      },
      {
        path: "/CreateCompany",
        name: "CreateCompany",
        component: () => import("@/components/Company/CreateCompany.vue"),
      },
      {
        path: "/EditCompany",
        name: "EditCompany",
        component: () => import("@/components/Company/EditCompany.vue"),
      },
      {
        path: "/ViewCompany",
        name: "ViewCompany",
        component: () => import("@/components/Company/ViewCompany.vue"),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard"),
      },
      {
        path: "/Managebanners",
        name: "Managebanners",
        component: () => import("@/views/Managebanners"),
      },
      {
        path: "/Createbanners",
        name: "Createbanners",
        component: () => import("@/components/banners/Createbanners.vue"),
      },
      {
        path: "/Viewbanners",
        name: "Viewbanners",
        component: () => import("@/components/banners/Viewbanners.vue"),
      },
      {
        path: "/Editbanners",
        name: "Editbanners",
        component: () => import("@/components/banners/Editbanners.vue"),
      },
      {
        path: "/ManageLogistic",
        name: "ManageLogistic",
        component: () => import("@/views/ManageLogistic"),
      },
      {
        path: "/CreateLogistic",
        name: "CreateLogistic",
        component: () => import("@/components/Logistic/CreateLogistic.vue"),
      },
      {
        path: "/ViewLogistic",
        name: "ViewLogistic",
        component: () => import("@/components/Logistic/ViewLogistic.vue"),
      },
      {
        path: "/EditLogistic",
        name: "EditLogistic",
        component: () => import("@/components/Logistic/EditLogistic.vue"),
      },
      {
        path: "/ManageCoupon",
        name: "ManageCoupon",
        component: () => import("@/views/ManageCoupon"),
      },
      {
        path: "/CreateCoupon",
        name: "CreateCoupon",
        component: () => import("@/components/Coupon/CreateCoupon.vue"),
      },
      {
        path: "/ViewCoupon",
        name: "ViewCoupon",
        component: () => import("@/components/Coupon/ViewCoupon.vue"),
      },
      {
        path: "/EditCoupon",
        name: "EditCoupon",
        component: () => import("@/components/Coupon/EditCoupon.vue"),
      },
      {
        path: "/ManageVoucher",
        name: "ManageVoucher",
        component: () => import("@/views/ManageVoucher"),
      },
      {
        path: "/CreateVoucher",
        name: "CreateVoucher",
        component: () => import("@/components/Voucher/CreateVoucher.vue"),
      },
      {
        path: "/ViewVoucher",
        name: "ViewVoucher",
        component: () => import("@/components/Voucher/ViewVoucher.vue"),
      },
      {
        path: "/EditVoucher",
        name: "EditVoucher",
        component: () => import("@/components/Voucher/EditVoucher.vue"),
      },
      {
        path: "/Managetips",
        name: "Managetips",
        component: () => import("@/views/Managetips"),
      },
      {
        path: "/Createtips",
        name: "Createtips",
        component: () => import("@/components/tips/Createtips.vue"),
      },
      {
        path: "/Viewtips",
        name: "Viewtips",
        component: () => import("@/components/tips/Viewtips.vue"),
      },
      {
        path: "/Edittips",
        name: "Edittips",
        component: () => import("@/components/tips/Edittips.vue"),
      },
      {
        path: "/Managejob",
        name: "Managejob",
        component: () => import("@/views/Managejob"),
      },
      {
        path: "/Createjob",
        name: "Createjob",
        component: () => import("@/components/job/Createjob.vue"),
      },
      {
        path: "/Viewjob",
        name: "Viewjob",
        component: () => import("@/components/job/Viewjob.vue"),
      },
      {
        path: "/Editjob",
        name: "Editjob",
        component: () => import("@/components/job/Editjob.vue"),
      },
      {
        path: "/Managelicenses",
        name: "Managelicenses",
        component: () => import("@/views/Managelicenses"),
      },
      {
        path: "/Createlicenses",
        name: "Createlicenses",
        component: () => import("@/components/licenses/Createlicenses.vue"),
      },
      {
        path: "/Viewlicenses",
        name: "Viewlicenses",
        component: () => import("@/components/licenses/Viewlicenses.vue"),
      },
      {
        path: "/Editlicenses",
        name: "Editlicenses",
        component: () => import("@/components/licenses/Editlicenses.vue"),
      },
      {
        path: "/Manageskills",
        name: "Manageskills",
        component: () => import("@/views/Manageskills"),
      },
      {
        path: "/Createskills",
        name: "Createskills",
        component: () => import("@/components/skills/Createskills.vue"),
      },
      {
        path: "/Viewskills",
        name: "Viewskills",
        component: () => import("@/components/skills/Viewskills.vue"),
      },
      {
        path: "/Editskills",
        name: "Editskills",
        component: () => import("@/components/skills/Editskills.vue"),
      },
      {
        path: "/Report1",
        name: "Report1",
        component: () => import("@/components/Report/Report1.vue"),
      },
      {
        path: "/Setting",
        name: "Setting",
        component: () => import("@/views/Setting.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
