<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app color="#001529">
        <v-list height="180" class="mt-3">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <!-- <v-avatar size="120">
                <img src="../assets/logo_color.png" />
              </v-avatar> -->
              <v-img
                src="https://health-express-web.yuzudigital.com/assets/images/bottom_icon.png"
                max-height="300px"
                max-width="150px"
              ></v-img>
            </a-col>
            <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <!-- <a-menu mode="vertical">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu> -->
        <a-menu mode="inline" @click="handleClick" theme="dark">
          <a-sub-menu
            @titleClick="titleClick"
            v-for="item in Navigator"
            :key="item.key"
          >
            <span slot="title">
              <span>{{ item.headerName }}</span>
            </span>
            <a-menu-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
            >
              <a-icon :type="menuList.icon" />
              {{ menuList.text }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#1E2C3E">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon>
        <v-img
          src="https://health-express-web.yuzudigital.com/img/logo_home.8a04b7b4.png"
        ></v-img>

        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout()"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <!-- <v-container grid-list-xs> -->
        <div class="pa-4">
          <router-view></router-view>
        </div>
        <!-- </v-container> -->
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      NavigatorDefault: [
        // {
        //   headerName: "แดชบอร์ด",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "แดชบอร์ด",
        //       path: "/Dashboard",
        //       name: "Dashboard",
        //       icon: "dashboard",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายการสั่งซื้อ",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "ออเดอร์",
        //       path: "/ManageOrder",
        //       name: "ManageOrder",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายงาน",
        //   menuItem: [
        //     {
        //       key: "report1",
        //       text: "ยอดขายรวม ",
        //       path: "/report1",
        //       name: "report1",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            // {
            //   key: "menu1",
            //   text: "หมวดหมู่สินค้า",
            //   path: "/ManageCategory",
            //   name: "ManageCategory",
            //   icon: "menu",
            // },
            // {
            //   key: "menu2",
            //   text: "ประเภทสินค้า",
            //   path: "/ManageType",
            //   name: "ManageType",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu3",
            //   text: "ยี่ห้อสินค้า",
            //   path: "/ManageBrand",
            //   name: "ManageBrand",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu4",
            //   text: "การจัดส่ง",
            //   path: "/ManageLogistic",
            //   name: "ManageLogistic",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu5",
            //   text: "สินค้า365",
            //   path: "/ManageProduct365",
            //   name: "ManageProduct365",
            //   icon: "shopping",
            // },
            // {
            //   key: "menu6",
            //   text: "สินค้า",
            //   path: "/ManageProduct",
            //   name: "ManageProduct",
            //   icon: "shopping",
            // },
            // {
            //   key: "menu7",
            //   text: "คูปองส่วนลด",
            //   path: "ManageCoupon",
            //   name: "ManageCoupon",
            //   icon: "barcode",
            // },
            // {
            //   key: "menu8",
            //   text: "จัดการบัตรเงินสด",
            //   path: "ManageVoucher",
            //   name: "Voucher",
            //   icon: "dollar",
            // },
            {
              key: "menu9",
              text: "ผู้ใช้งาน",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "user",
            },
            // {
            //   key: "menu10",
            //   text: "แบนเนอร์",
            //   path: "/Managebanners",
            //   name: "Managebanners",
            //   icon: "snippets",
            // },
            {
              key: "menu11",
              text: "เรียนรู้เพิ่มเติม",
              path: "/Managetips",
              name: "Managetips",
              icon: "notification",
            },
            {
              key: "menu12",
              text: "อาชีพ",
              path: "/Managejob",
              name: "Managejob",
              icon: "unordered-list",
            },
            {
              key: "menu13",
              text: "ใบอนุญาติประกอบวิชาชีพ",
              path: "/Managelicenses",
              name: "Managelicenses",
              icon: "unordered-list",
            },
            {
              key: "menu14",
              text: "ทักษะ-สกิล",
              path: "/Manageskills",
              name: "Manageskills",
              icon: "unordered-list",
            },
          ],
        },
        // {
        //   headerName: "ตั้งค่า",
        //   menuItem: [
        //     {
        //       key: "setting",
        //       text: "ตั้งค่าระบบ ",
        //       path: "/setting",
        //       name: "setting",
        //       icon: "setting",
        //     },
        //   ],
        // },
        // {
        //   key: "menu0",
        //   text: "แดชบอร์ด",
        //   path: "/Dashboard",
        //   name: "Dashboard",
        //   icon: "dashboard",
        // },
        // {
        //   key: "menu1",
        //   text: "หมวดหมู่สินค้า",
        //   path: "/ManageCategory",
        //   name: "ManageCategory",
        //   icon: "menu",
        // },
        // {
        //   key: "menu2",
        //   text: "ประเภทสินค้า",
        //   path: "/ManageType",
        //   name: "ManageType",
        //   icon: "unordered-list",
        // },
        // {
        //   key: "menu3",
        //   text: "ยี่ห้อสินค้า",
        //   path: "/ManageBrand",
        //   name: "ManageBrand",
        //   icon: "unordered-list",
        // },
        // {
        //   key: "menu4",
        //   text: "บริษัท",
        //   path: "/ManageCompany",
        //   name: "ManageCompany",
        //   icon: "unordered-list",
        // },
        // {
        //   key: "menu5",
        //   text: "สินค้า",
        //   path: "/ManageProduct",
        //   name: "ManageProduct",
        //   icon: "shopping",
        // },
        // {
        //   key: "menu6",
        //   text: "ออเดอร์",
        //   path: "/ManageOrder",
        //   name: "ManageOrder",
        //   icon: "user",
        // },
        // {
        //   key: "menu7",
        //   text: "คูปองสินค้า",
        //   path: "",
        //   name: "ManageCoupon",
        //   icon: "user",
        // },
        // {
        //   key: "menu8",
        //   text: "บัตรเงินสก",
        //   path: "",
        //   name: "ManageCashcard",
        //   icon: "user",
        // },
        // {
        //   key: "menu9",
        //   text: "ผู้ใช้งาน",
        //   path: "/ManageUser",
        //   name: "ManageUser",
        //   icon: "user",
        // },
      ],

      Navigator: [],
    };
  },
  created() {
    // this.Navigator = this.NavigatorDefault;
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("userHealthAdmin"))
    );
    console.log("user", checkuser);
    this.username = checkuser.name;
    console.log("username", this.username);

    this.Navigator = this.NavigatorDefault;

    this.CheckHeader();
  },
  methods: {
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("userHealthAdmin");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  /* background-color: rgb(235, 240, 248); */
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
